.locontainer{
    width: 100%;
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loform{
    width: 300px;
}
.loimage{
    width: 240px;
    margin: 0 auto 10px auto;
}
.loform h3{
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    margin: 0;
}

.loform form{
    display: flex;
    width: 300px;
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 30px;
    background: #42414D;
}

.label{
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1.125px;
    text-transform: uppercase;
    line-height: 0;
    margin: 0;
}
.loError{
    width: 100%;
    color:orange;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.125px;
    text-transform: uppercase;
}
.loform form input{
    margin: 0;
    margin-bottom: 10px;
    border-radius: 5px;
    background: #FFF;
    width: 100%;
    color: #000;
    font-size: 12px;
    padding: 5px;
    border: 0;
}
.loform form button{
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;  
    border-radius: 10px;
    background: #FFF;
    color: #2B2A33;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    border: 0;
}

.loform form button:hover{
    background: #2B2A33;
    color: #fff;
}


