.pagesContent2{
    position: absolute;
  left: 250px;
  top: 145px;
  width: calc(100% - 250px);
  z-index: -5;
  padding: 15px;
}
.tableContainer{

    width:auto;
    z-index: -5;
}
table.GeneratedTable {
    width: fit-content;
    background-color: #ffffff;
    border-collapse: collapse;
    border-width: 1px;
    border-color: #000000;
    border-style: solid;
    color: #000000;
  }
  
  table.GeneratedTable td, table.GeneratedTable th {
    border-width: 1px;
    border-color: #000000;
    border-style: solid;
    padding: 3px;
    width: 500px;
    white-space: nowrap;
  }
  
  table.GeneratedTable thead {
    background-color: #e5e5e5;
  }

  @media screen and (max-width: 1024px) {
    .pagesContent2{
     
    left: 0;
    top: 250px;
    width:100%;
    z-index: -5;
  }
  .tableContainer{
    width:100%;
    overflow-x: auto;
}
table.GeneratedTable {
  width:100% ;
  max-width: 100%;
}
table.GeneratedTable td, table.GeneratedTable th {
overflow: hidden;
  width: auto;

}

  }