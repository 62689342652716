@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600;700&family=Ubuntu:wght@300;400;500;700&display=swap');
.todoWarper{
     font-family: 'Ubuntu', sans-serif;
    display: flex;
    justify-content: space-between;
    width: 60%;
    right: 20px;
    top: 20px;
    position: absolute;
   max-height: 47px;
}
.lista{
    flex: 1;
    margin-right: 10px;
}
.lista:last-child{
    margin-right: 0;
}
.listafixo{
display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: #42414D;
  color: #FFF;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  flex: 1;
  z-index: 500;
  
}

.texto{
    width: 100%;
}
.abrir, .fechar{
   width: 25px;
  height: 25px;
  border: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px; 
  cursor: pointer;
}
.abrir img{rotate: 180deg;}
.fechar img{rotate: 0deg;}

.listagem{
    width: 100%;
    background-color: #FFF;
    color:#000;
    font-size: 14px;
    padding: 10px;
    opacity: 0;
    top:-1000px;
    transition: all ease 500ms;
    position: relative;
}

.ativa{
    opacity: 1;
    top:5px;
}

.listagemItens{
    max-height: 450px;
    overflow-y: auto;
}

.mensagens{
 display: flex;
 border: 1px solid #2b2a33;
 margin-bottom: 10px;
}
.mensagemTexto{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    overflow: hidden;
}
.mensagemTexto .data{
    border-bottom:1px solid #2b2a33;
    padding: 2px; 
}
.mensagemTexto .texto{
    padding: 2px;
    word-wrap: break-word;
    max-width: 258px; 
}
.mensagemTexto .usuario{
    border-top:1px solid #2b2a33;
    padding: 2px; 
}
.mensagemEdit{
    width:50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 15px;
    align-items: center;
    border-left:1px solid #2b2a33 ;
    padding: 5px;
}
.mensagemEdit button{
    width: 100%;
    font-size: 20px;
    border: 0;
    background-color: transparent;
    color: #01B4DB;
    cursor: pointer;
}

.mensagemEdit button svg path{
    fill:#000 ;
}

.mensagemEdit button:hover svg path{
    fill: #db8701;
}
.botaoLista button, .botaoLista form input{
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  width: 100%;
  height: 35px;
  color: #000;
  background-color:#01B4DB ; 
  border: 0;
  font-size: 20px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}
.botaoLista button:hover{
    background-color:#db8701 ;
}
.addTD{
   width: 100%;
   height: 80px;
}@media screen and (max-width: 1300px) {
    
    .todoWarper{max-width: 950px;}
    .abrir, .fechar{
   width: 20px;
  height: 20px;}

  .listafixo{
  padding: 5px;
  font-size: 12px;
  
}

.botaoLista button, .botaoLista form input{
  
  font-size: 12px;
}
    }



@media screen and (max-width: 860px) {.todoWarper{display: none;}}