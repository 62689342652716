.SBtopLogo{
    display: flex;
    width: 250px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-right: 1px solid #FFF;
    background: #42414D;
}
.logoDesk{
    display: block;
}
.logoMob{
    display: none;
}
.SBsectionName{
    display: flex;
    background-color: #01B4DB;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border-right: 1px solid #fff;
}

.SBclock, .SBLogout, .SBNav{
    width: 100%;
}

.SBClockContent{
    display: flex;
    width: 250px;
    padding: 10px 15px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-right: 1px solid #FFF;
    background: #42414D;
}
.SBclockTimeDisplay{
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.SBclockCityDisplay{
    background-color: #42414D;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    border: 1px solid #fff;
}

.SBLogout button{
    display: flex;
    width: 250px;
    padding: 15px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #01B4DB;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border:0;
    border-right: 1px solid #FFF;
}

.SBLogout button:hover{
    background: #42414D;
    color: #fff;
    border: 1px solid #FFF;
}

.SBContent{
    display: flex;
    width: 250px;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-right: 1px solid #FFF;
    background: #42414D;
}

.SBContent a{
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    background: #FFF;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid #FFF;
}
.SBContent a:hover{
    background: #42414D;
    color: #fff;
    
} 
.SBNavMob, .SBNavMenuMob {display: none;}

@media screen and (max-width: 1024px) {
    .SBtopLogo{
        width: 40px;
        height: 40px;
        border:0;
        padding: 0;
        background: transparent;
    }
    .logoDesk{
        display: none;
    }
    .logoMob{
        display: block;
    }
    .SBNav{
        display: none;
    }
    .SBclock{
        display: none;
    }
    .SBLogout{display: none;}

    .SBNavMob{
        display: block;
        width: 40px;
        height: 40px;
        padding: 5px;
        border-radius: 5px;
        background: #42414D;
    }
    .SBNavMenuMob{
        position: absolute;
        left: 50%;
        width: 100%;
        max-width: 500px;
        z-index: 99;
        transition: all ease 1s;
        display: block;
        padding: 0 50px;
        top:-500px;
        opacity: 0;
        margin-left: -250px;
    }
    .SBNavMenuMobActive{
        top:61px;
        opacity: 1;
        transition: all ease 1s;
    }
    .SBNavMenuMob .SBContent, .SBNavMenuMob .SBClockContent{
        width: 100%;
        border: 0;
    }
   
}

@media screen and (max-width: 500px) {

    .SBNavMob{
        width: 25px;
        height: 25px;
    }
    .SBtopLogo{
        width: 25px;
        height: 25px;
    }
    .SBNavMenuMobActive{
        top:46px;
    }
    .SBNavMenuMob{
        left: 0;
        width: 100%;
        margin-left: 0;
        padding: 0;
    }
  
}


