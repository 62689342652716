.sideBar{
  width: 250px;
  position:fixed;
  left: 0;
  top: 0;
  height:100%;
  overflow-x: hidden;
  scrollbar-width: none;
}

.High{
  background-color: #FA9600;
}

.alerta{
  background-color: #FA9600;
  width: 100%;
  padding: 5px;
  color:#fff;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}


.sideBar::-webkit-scrollbar{ width: 0;}

.sideBar2{
  position: absolute;
  top: 0;
  left: 0;
  width:250px;
  height: fit-content;
}
.pageHeader{
  position: fixed;
  left: 250px;
  top: 0;
  width: calc(100% - 250px);
  background-color: #2B2A33;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 15px 25px 15px;
  z-index: 1;
}
.pagesContent{
  position: absolute;
  left: 250px;
  top: 145px;
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 15px;
}
.pagesContentup{
  position: absolute;
  left: 250px;
  top: 80px;
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 15px;
}

.pagesContentEmail{
  position: absolute;
  left: 250px;
  top: 175px;
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 15px;
}
.sideBarMob{
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #FFF;
  display: none;
}

/*titulo pagina*/
.pageTitle{
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: #42414D;
  color: #FFF;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.pagetitleButton{
  display: flex;
}
.pagetitleButton a{
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #FFF;
  background: #fff;
  color: #42414D;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 15px;
  text-decoration: none;
}
.pagetitleButton :hover{
  background: #42414D;
  color: #fff;
}
/*titulo a esquerda*/
.pageTitleLeft{
  width: 1300px;
  display: flex;
  justify-content: left;
  padding: 10px 0;
}
.pageTitleFull{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.pageTitleLeftEmail{
  width: 1300px;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.pageTitle span{
  font-size: 15px;
  font-weight: 500;
}

/*titulo e conteudo centralizado*/
.pageTitleCenter, .pageContentCenter{
  width: 100%;
  display: flex;
  justify-content: center;
}
.pageTitleCenter{ padding: 10px 0;}

/*pagina de pricing*/
.pricingContent{
  display: flex;
  width: 600px;
  padding: 25px 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #FFF;
  background: #42414D;
  height: fit-content;
}
.pricingItem{
  width: 100%;
  display: flex;
  gap:5px;
}
.pricingItemNane{
  flex:1;
  border: 1px solid #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 5px;
}
.pricingItemCotation{
  display: flex;
  width: 120px;
  border: 1px solid #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

/*botão edição*/
.editValues{ 
  background-color: #FFF;
  color: #42414D;
  border-radius: 10px;
  color: #2B2A33;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 5px;
  width: 100%;
  border: 1px solid #fff;
  margin-top: 15px;
}
.editValues:hover{
  background-color: #2B2A33;
  color: #fff;
}
.editValuesG{ 
  background-color: #055a14;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 15px;
  width: fit-content;
  text-decoration: none;
  border: 1px solid #fff;
  margin-top: 15px;
}
.editValuesG:hover{
  background-color: #2B2A33;
}

/*PopUP*/
.popUp{
  position: absolute;
  top: 0;
  left: 0;
 min-width: 100%;
 min-height: 100%;
  display: flex;
  z-index: 9999;
  background-color: #2b2a33e5;
  justify-content: center;
}
.popUp2{
  position: absolute;
  top: 0;
  left: 0;
 min-width: 100%;
 min-height: 100%;
  display: flex;
  z-index: 99999;
  background-color: #2b2a33e5;
  justify-content: center;
}

.popUpDelete{
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
}

.pricingItemUpdate{
  width: 100%;
  display: flex;
  gap:5px;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
}

/*pagina de upclientes  formulario*/
.form900, .form600, .form100, .formpProj{
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: #42414D;
}
.formpProj{width: 100%;height: fit-content;}
.form900{width: 900px;height: fit-content;}
.form600{width: 600px;height: fit-content;}
.form100{width: 1300px; height: fit-content;}
.form900 input[type=submit], .form600 input[type=submit], .form100 input[type=submit], .formpProj input[type=submit]{
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #FA9600;
  color: #2B2A33;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
}
.form900 input[type=submit]:hover, .form600 input[type=submit]:hover , .form100 input[type=submit]:hover, .formpProj input[type=submit]:hover{
  background: #fff;
}
.linhas{
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.linhaCentral{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
}
.linhasTitle{
  display: flex;
  width: 177px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  color: #2B2A33;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.clientName{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  flex: 2;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.clientPhone{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  flex: 1;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.clientName p, .clientName input, .clientName textarea, .clientPhone p, .clientPhone input, .clientPhone select{
  align-self: stretch;
  margin: 0;
  padding: 0;
}
.clientCountry{
  width:150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.clientCountry p, .clientCountry select , .pageTitle select{
  margin: 0;
  padding: 0;
}
.clientCountry select, .clientPhone select, .pageTitle select{
  color:#42414D;
  background-color: #fff;
  border: 1px solid #fff;
}
.clientStat{
  width:50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.clientStat p, .clientStat input{
  align-self: stretch;
  margin: 0;
  padding: 0;
}

.radio{
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.radio p:last-child{
  padding-right: 20px;
}
.clientFlex1Button{
  flex:1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clientFlex1Button{
  flex:1;
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
}
.clientFlex1Button2{
  flex:1;
  display: flex;
  align-self: stretch;
}
.clientFlex1Button button:last-child{
 margin-left: 10px;
}
.clientFlex1Button2 button, .clientFlex1Button button{
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #FA9600;
  color: #2B2A33;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0;
  width: 100%;
  height: 100%;
}
.clientFlex1Button2 button:hover, .clientFlex1Button button:hover{
  background-color: #fff;
}

/*Page List*/

.pageListProjects{
  width: 100%;
  border-radius: 6px;
  border: 1px solid #FFF;
  background: #42414D;
  padding: 10px;
}
.pageListCLients, .pageListSearch, .pageListEmail{
  width: 1300px;
  border-radius: 6px;
  border: 1px solid #FFF;
  background: #42414D;
  padding: 10px;
}
.pageListEmail{
  display: flex;
}
.pageListSearchbutton{
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #FFF;
  background: #fff;
  color: #42414D;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 15px;
  text-decoration: none;
}
.pageListSearchbutton:hover{
  background: #42414D;
  color: #fff;
}

.pageListSearch:last-child{
  margin-top: 10px;
}
.pageListCLientsA{
  display: flex;
  width: 100%;
  gap: 8px;
}
.pageListCLientsBLinhas:first-child{margin-top: 8px;}
.pageListCLientsB{
  flex-direction: column;
  width: 100%;
  gap: 8px;
  display: none;
}

.pageListCLientsBLinhas{
  display: flex;
  width: 100%;
  gap: 8px;
}
.listActive{display: flex;}
.pageListSearch{
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
}
.pageListSearchAelect{
  display: flex;
  align-items: center;
  gap: 5px;color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 300px;
}
.pageListSearchAelect select{
  color:#42414D;
  background-color: #fff;
  border: 1px solid #fff;
}
.pageListSearcInput{
  flex:1;
}
.pageListSearcInput input {
  width: 100%;
}
.pageListSearcInput2{
  flex:1;
  overflow: hidden;
  white-space: nowrap;
}
.pageListSearcClear button{
  width: 20px;
  height: 20px;
  background-color: #fff;
  color: #2B2A33;
  font-weight: 800;
  border: 0;
  border: 1px solid #fff;
  border-radius: 3px;
}
.pageListSearcClear button:hover{
 background-color: #2B2A33;
 color: #fff;
}
.clientFlex2, .clientFlex1, .clientW0, .clientW01, .clientFit{
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border: 1px solid #FFF;
  overflow: hidden;
}

.clientFlex2 span, .clientFlex2 p, .clientFlex1 span, .clientFlex1 p, .clientW0 span, .clientW0 p, .clientFlex1 a, .clientFit span, .clientFit p{
  margin: 0; padding: 0;  display: block; white-space: nowrap;
}
.clientFlex2 span, .clientFlex1 span, .clientW0 span, .clientW01 span, .clientFit span { font-size: 10px; line-height: 10px; text-transform: capitalize;}
.clientFlex2 p, .clientFlex1 p, .clientW0 p, .clientFlex1 a, .clientW01 p, .clientFit p{font-size: 15px; line-height: 18px; text-transform: capitalize; }
.clientFlex2{
  flex:2
}
.clientFlex1{
  flex: 1;
}
.clientW0{
  width: 175px;
}
.clientW01{
  width: 100px;
}
.clientFit{
  width: fit-content;
}
.clientFlex2{flex: 2;}
.clientFlex2 p a{
  color: #fff;
  text-decoration: none;
}
.clientFlex2 p a:hover{
  color: #fff;
  text-decoration: underline;
}
.clientFlexTitle{
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.clientFlexP{
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.clientFlexBox{
  
  width: 100%;
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px;
  align-self: stretch;
  border: 1px solid #FFF;
}

.clientFlexBoxPre{
  font-family:"Ubuntu" sans-serif;
  font-size: 13px;
  max-width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.clientFlex1 a{
  color: #FFF;
  text-decoration: none;
}
.clientFlex1 a:hover{
  color: #FFF;
  text-decoration: underline;
}
.openClientContent{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.openClient, .closeClient{
  width: 25px;
  height: 25px;
  border: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}


.openClient img, .closeClient img{
  width: 100%;
  height: auto;
  rotate: 180deg;
}
.closeClient img{rotate: 0deg;}

.seeClient{ 
  width: 100%;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #FFF;
  border: 1px solid #fff;
  text-decoration: none;
  color:#42414D;
  text-transform: uppercase;
  font-weight: 900;
}
.seeClient2{ 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #42414d;
  text-decoration: none;
  color:#fff;
  border: 0;

}
.seeClient:hover{
  color: #fff;
  background-color: #2B2A33;
}
.seeClient2:hover{
  color: #42414d;
  background-color: #fff;
}

.seeClientG{ 
  width: 100%;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #055a14;
  border: 1px solid #fff;
  text-decoration: none;
  color:#fff;
  text-transform: uppercase;
  font-weight: 900;
}
.seeClientG:hover{
  color: #42414d;
  background-color: #fff;
}


/*pagina de up projetos  formulario*/
.analistasFlex1{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.linhaProjeto{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px; 
}
.linhaProjetoCalc{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px; 
}
.linhaCalculado{
  display: flex;
  width: 100%;
  gap: 8px;
}
.proejctTitle{
  display: flex;
  width: fit-content;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #FFF;
  color: #2B2A33;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.projFit, .projF1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.projF1-2{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  border: 1px solid #fff;
  padding: 5px;
}

.projFit{width: fit-content;}

.projFit p, .projF1 p, .projF1-2 p{
  margin: 0; padding: 0;  display: block;
}
.projFit span{
  margin: 0; padding: 0;  display: block;
  font-size: 15px;
  width: 100%;
  text-align: center;
}
.projF1-2 div span{
  margin: 0; padding: 0;
  font-size: 15px;
  font-weight: 700;
}

.projF1, .projF1-2{
  flex:1;
}
.projF1 input, .projF1 select, .projFit select, .projFit input, .clientFlex1 select{
  width: 100%;
  margin: 0;
  padding: 0;
}
.projF1 select , .projFit select, .clientFlex1 select{
  color:#42414D;
  background-color: #fff;
  border: 1px solid #fff;
}

.deleteAviso{
  width: 280px;
  color:#000;
  border-radius: 15px;
  padding: 15px;
  border: 4px solid #2B2A33;
  background-color: #FFF;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
}
.deleteButtons{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.deleteNao{
  background-color: #FA9600;
  padding: 10px;
  color:#FFF;
  border: 0;
  font-size: 20px;
  font-weight: 800;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid #2B2A33;
}
.deleteNao:hover{
  background-color:#2B2A33 ;
}

.deleteSim{
  background-color: #fff;
  padding: 10px;
  color:#2B2A33;
  border: 0;
  font-size: 20px;
  font-weight: 800;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid #2B2A33;
}
.deleteSim:hover{
  background-color:#FA9600;
  color:#fff;
}


/*medias*/
@media screen and (max-width: 1640px){
  .linhaProjeto{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
  .linhaProjetoCalc{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows:repeat(2, 1fr);
    grid-row-gap: 8px; 
  }
}
@media screen and (max-width: 1550px){
  .pageTitleLeft, .pageListCLients, .pageListSearch, .pageListEmail, .form100, .pageTitleLeftEmail{
    width: 100%;
  }
}
@media screen and (max-width: 1150px){
  .form900{
    width: 100%;
  }
  .linhas{
    flex-direction: column;
  }
  .clientName, .clientCountry, .clientPhone, .clientStat{
    flex: none;
    width: 100%;
  }
  .clientStat input{
    width: 50px;
  }
  .radio{
    justify-content:flex-start;
  }
  .radio p:last-child{
    margin-left: 40px;
  }
  
}

@media screen and (max-width: 1024px) {
  .sideBar{display: none;}
  .sideBarMob{
    display: flex;
    position: fixed;
    z-index: 2;
    background-color: #2B2A33;
    } 

    .linhaProjeto{
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr); 
    }
    .linhaProjetoCalc{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows:repeat(2, 1fr);
      grid-row-gap: 8px; 
    }
    .linhaCalculado{
      flex-direction: column;
    }
  .pageHeader{
    position: absolute;
    left: 0;
    top: 61px;
    width: 100%;
  }
  .pagesContent{
    position: absolute;
    left: 0;
    top: 244px;
    width:100%;
}
.pagesContentup{
  position: absolute;
  left: 0;
  top: 140px;
  width:100%;
}
.pagesContentEmail{
  left: 0;
  top: 250px;
  width:100%;
}
.pageListSearcInput2{
  display: none;
}

}

@media screen and (max-width: 768px) {

  .form600, .clientW0, .pricingContent, .pricingItemUpdate, .pricingItemNane, .clientFit{
    width: 100%;
  }
  .pageListCLientsA, .pageListCLientsBLinhas, .pricingItemUpdate{
    flex-direction: column;
  }
  .openClientContent, .openClient, .closeClient{
    width: 100%;
  }
  .openClient img, .closeClient img{
    width: auto;
    height: 100%;
  }
  .linhaProjeto{
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(10, 1fr); 
  }
  .linhaProjetoCalc{
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows:repeat(6, 1fr);
  }
}

