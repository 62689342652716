
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@600;700&family=Ubuntu:wght@300;400;500;700&display=swap');

*{
  box-sizing: border-box;
}
body {
 background-color: #2B2A33;
 color:#fff;
 font-family: 'Ubuntu', sans-serif;
 margin: 0;
 padding: 0;
}



